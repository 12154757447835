/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, TitleMain, PriceWrap, Text, Subtitle } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ke stažení"}>
        <ColumnCover name={"g5a9uwv0uq"}>
          
          <Column className="--menu pb--12 pt--12" anim={null} menu={true} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
            
            <Menu className="--full" fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box pl--06">
                
                <Image className="--left" alt={"Logo PROFICOMFORT – infratopení"} src={"https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=660x_.png"} svg={false} url={"https://proficomfort.cz"} use={"url"} href={"https://proficomfort.cz"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":240}} srcSet={"https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=350x_.png 350w, https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=660x_.png 660w, https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=860x_.png 860w"} target={null}>
                </Image>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" href={"/"} target={""} content={"Úvod"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" href={"/o-nas"} target={""} content={"O NÁS"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" url={"https://proficomfort.cz/infrapanely-do-domacnosti/"} use={"url"} href={"https://proficomfort.cz/infrapanely-do-domacnosti/"} target={""} content={"infrapanely"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" href={"https://proficomfort.cz/#produkty"} target={""} content={"PRODUKTY"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" target={""} content={"KE STAŽENÍ"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01" innerClassName="pb--02 pl--0 pr--0" href={"/kontakt"} target={""} content={"KONTAKT"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="mt--60 pb--60 pt--60" name={"szq16opjo9n"} style={{"backgroundColor":"rgba(216,36,36,1)"}}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1440}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":""}}>
              
              <TitleMain className="title-box w--900 title-box--invert" content={"Ke stažení"}>
              </TitleMain>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"5ltuiv1mn13"}>
          
          <ColumnWrap className="column__flex el--1" style={{"maxWidth":1440}} columns={"1"}>
            
            <ColumnWrapper className="pl--20 pr--20">
              
              <PriceWrap className="flex--center" style={{"backgroundColor":"rgba(244, 246, 251, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18 w--500 ls--001 lh--2" content={"<span style=\"color: rgb(78, 80, 97);\">Katalog</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 fs--16 w--900 swpf--uppercase" innerClassName="pb--02 pl--0 pr--0" href={"https://cdn.swbpg.com/d/11212/20190807-pc-katalog-pdf"} content={"Stáhnout"}>
                  </Button>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="flex--center" style={{"backgroundColor":"rgba(244, 246, 251, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18 w--500 ls--001 lh--2" content={"<span style=\"color: rgb(78, 80, 97);\">Leták</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 fs--16 w--900 swpf--uppercase" innerClassName="pb--02 pl--0 pr--0" href={"https://cdn.swbpg.com/d/11212/20190902-pc-letak-a5-pdf"} content={"Stáhnout"}>
                  </Button>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1knc10f bg--center --parallax pb--60 pt--60" style={{"backgroundColor":"rgba(25, 24, 30, 1)"}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--3 mb--40 mt--25" style={{"maxWidth":1440}} columns={"3"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image alt={"Logo – infratopení – PROFICOMFORT"} src={"https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=660x_.png"} svg={false} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=350x_.png 350w, https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=660x_.png 660w, https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=860x_.png 860w"} content={null}>
              </Image>

              <Text className="text-box fs--18 w--600 text-box--invert mt--25" content={"<span style=\"color: rgb(127, 129, 150);\">Hosov 65<br>586 01 Jihlava</span><br>"}>
              </Text>

              <Text className="text-box fs--18 w--600 text-box--invert mt--10" content={"<span style=\"color: rgb(127, 129, 150);\">+420 776 117 775<br>nekula@proficomfort.cz</span><br>"}>
              </Text>

              <Image className="pl--06" alt={""} src={"https://cdn.swbpg.com/o/11212/44bad18f84e0428187e9d7bd02eaea99.svg"} svg={false} href={"https://www.facebook.com/proficomfort/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":27}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left pt--10" style={{"maxWidth":344}}>
              
              <Subtitle className="subtitle-box mb--30" content={"<span style=\"color: rgb(255, 255, 255);\">další Produkty</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" url={"https://proficomfort.cz/infrapanely-do-domacnosti/"} use={"url"} href={"https://proficomfort.cz/infrapanely-do-domacnosti/"} content={"<span style=\"color: rgb(127, 129, 150);\">Infrapanely do domácnosti</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/elektricke-podlahove-topeni"} content={"<span style=\"color: rgb(127, 129, 150);\">Elektrické podlahové topení</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/prumyslove-salave-zarice"} content={"<span style=\"color: rgb(127, 129, 150);\">Průmyslové sálavé zářiče</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/halogenove-zarice"} content={"<span style=\"color: rgb(127, 129, 150);\">Halogenové zářiče</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--right pt--10" style={{"maxWidth":344}}>
              
              <Subtitle className="subtitle-box mb--30" content={"<span style=\"color: rgb(255, 255, 255);\">Navigace</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/"} content={"<span style=\"color: rgb(127, 129, 150);\">Úvod</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"https://proficomfort.cz/#produkty"} target={null} content={"<span style=\"color: rgb(127, 129, 150);\">Produkty</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/o-nas"} content={"<span style=\"color: rgb(127, 129, 150);\">O nás</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/ke-stazeni"} content={"<span style=\"color: rgb(127, 129, 150);\">Ke stažení</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/kontakt"} content={"<span style=\"color: rgb(127, 129, 150);\">Kontakt</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"https://cdn.swbpg.com/d/37846/gdpr.pdf"} content={"<span style=\"color: rgb(127, 129, 150);\">GDPR</span><br>"} use={"file"} document={"13b986599b3d4928a142c6ca75a3df4a"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--10 pt--10" anim={null} animS={null} style={{"backgroundColor":"rgba(216,36,36,1)"}} border={null}>
          
          <ColumnWrap className="column__flex --center el--2" style={{"maxWidth":1440}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--left fs--14 w--600 text-box--invert" content={"<a href=\"https://saywebpage.com/cs/\" style=\"color: rgb(255, 255, 255);\">Tvorba webových stránek</a>: saywebpage.com<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--right fs--18 w--600 text-box--invert" content={"2024<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}